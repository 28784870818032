<template>
  <main class="layout-wrapper">
    <HeaderPanel class="header-panel-ansurance" />
    <div class="layout-main-ansurance" ref="layoutMainAnsurance">
      <div class="content" ref="contentMain">
        <Breadcrumb v-if="$route.meta.breadcrumb" class="mb-3 hidden sm:flex" ref="breadcrumbMain" :list="$route.meta.breadcrumb" />
        <BreadcumbCard v-if="$route.meta.breadcrumb" :list="$route.meta.breadcrumb" class="flex sm:hidden" />
        <router-view v-slot="{ Component }">
          <transition name="cust" enter-active-class="animate__animated animate__delay-1s animate__fast animate__fadeInRight" leave-active-class="animate__animated animate__fadeOutRight animate__faster">
            <component :is="Component" ref="bodyMain" />
          </transition>
        </router-view>
      </div>
    </div>
    <ConfirmDialog class="dialog-confirm"></ConfirmDialog>
    <Dialog header="Opps, tidak ada koneksi internet" :dismissableMask="true" @hide="hideDialog()" position="bottom" :modal="true" v-model:visible="$store.state.network.noInternetModal">
      Kamu masih bisa menggunakan aplikasi, namun data transaksi akan disimpan dalam aplikasi.
    </Dialog>
  </main>
  <transition name="load-transition" enter-active-class="animate__animated animate__fast animate__fadeIn" leave-active-class="animate__animated animate__fadeOut animate__faster">
    <div class="w-screen h-screen flex items-center" v-if="$store.state.loading.show" style="background: rgba(0, 0, 0, 0.2); z-index: 1049; position: fixed; top: 0">
      <ProgressSpinner class="h-16" />
    </div>
  </transition>
</template>

<script>
import LeftMenu from '@/views/layouts/navigation/LeftMenu.vue'
import HeaderPanel from '@/views/layouts/panel/HeaderPanel.vue'
import Breadcrumb from '@/views/shared-components/Breadcrumb.vue'
import BreadcumbCard from '@/views/shared-components/BreadcumbCard.vue'
import {
  mapGetters,
  mapActions
} from 'vuex'
import { environment } from '@/utils'

export default {
  name: 'Main',
  computed: {
    ...mapGetters({
      sliderMenu: 'getToggleMenu'
    })
  },
  mounted() {
    const width = document.documentElement.clientWidth
    let fontSize = 16
    if (width < 1407) {
      fontSize = 14
    }
    this.$refs.contentMain.style.minHeight = `${document.documentElement.clientHeight - (5.8 * fontSize) - (1.1 * fontSize)}px`
    this.setSlider(false)
    this.checkLocalVersion()
  },
  methods: {
    hideDialog() {
      this.$store.dispatch('setNoInternet', false)
    },
    ...mapActions({
      setSlider: 'setToggleMenu'
    }),
    toggleMenu() {
      this.setSlider(!this.sliderMenu)
    },
    checkLocalVersion() {
      const version = localStorage.getItem('version')
      if (version !== environment.getVersion() && this.$store.state.device.isWeb) {
        localStorage.setItem('version', environment.getVersion())
        window.location.reload(true)
      }
    }
  },
  watch: {
    $route(to, from) {
      this.setSlider(false)
    }
  },
  components: {
    LeftMenu,
    HeaderPanel,
    Breadcrumb,
    BreadcumbCard
  }
}
</script>

<style src="@/assets/styles/scss/layout.scss" lang="scss"></style>
